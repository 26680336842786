import 'echarts-liquidfill';
export default {
  backgroundColor: 'transparent', //透明-背景色
  series: [
    {
      type: 'liquidFill', //水位图
      radius: '95%', //显示比例
      center: [ '50%', '50%' ], //中心点
      amplitude: 10, //水波振幅
      data: [ 0.5, 0.5, 0.5 ], // data个数代表波浪数
      color: [ '#C26645' ], //波浪颜色
      backgroundStyle: {
        borderWidth: 6, //外边框-宽度
        borderColor: '#E89A89', //边框颜色
        color: '#573024', //边框内部填充部分颜色
      },
      label: {
        //标签设置
        normal: {
          show:false,
        },
      },
      outline: {
        show: true,//最外层边框显示控制
        itemStyle: {
          borderWidth: 2, //外边框-宽度
          borderColor: '#EDA793', //边框颜色
          color: 'transparent', //边框内部填充部分颜色
        },
      },
    },
  ],
};
