<template>
  <div class="dry-service">
    <moduleTitle type="dw" title="农业主体"></moduleTitle>
    <div class="dry-service-echart">
      
      <myCharts :pdata="listData"></myCharts>
    </div>
  </div>
</template>

<script>
import moduleTitle from "./components/moduleTitle.vue";
// import Echart from '../../components/echarts/index.vue';
// import option from './config/service.js';

import myCharts from "./components/nyzt.vue";
import { getLsgnq } from "../../api/numberApi/index";
export default {
  components: {
    moduleTitle,
    myCharts,
    // Echart
  },
  props: {
    lsgnqData: {
      type: Object,
      default: {
        data1: [],
        data2: [],
      },
    },
  },
  data() {
    return {
      option,
      listData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    //调用接口
    async getData() {
      //获取粮食功能区数据
      await getLsgnq({
        pageNum: 1,
        pageSize: 10,
        fieldType: "农业主体",
      }).then((res) => {
        
        if (res.code == 1 && res.data.records.length) {
         
          this.listData = res.data.records.map((item) => {
            return {
              name: item.principalName,
              value: item.totalFieldArea,
            };
          });
          this.$forceUpdate();
        }
      });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-service {
  position: relative;
  width: companyW(455vw);
  height: companyH(322vh);
  background: rgba(0, 0, 0, 0.7);
  // border: companyW(1vw) solid;
 
  /* opacity: 0.74; */
  .dry-service-echart {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    // margin-top: companyH(40vh);
    width: companyW(410vw);
    height: companyH(260vh);
  }
}
</style>
