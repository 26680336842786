<template>
  <div class="dyr-service">
    <Title
      class="dyr-service-title"
      snTitle="snTitle"
      typeOf="number-page"
      :title="address + '数字农业'"
    ></Title>
    <div class="dyr-service-content">
      <div class="dyr-service-left">
        <Power :ntjbqk="ntjbqk"></Power>
        <Service class="dyr-service-service"></Service>
        <LastYear class="dyr-service-service"></LastYear>
      </div>
      <div class="dyr-service-middle">
        <!-- <Map v-on="$listeners" @btn="btn" @click="btn" /> -->
        <Mddle
          :divList="divList"
          :ntjbqk="ntjbqk"
          v-on="$listeners"
          :tzList="tzList"
          @btn="btn"
          @click="btn"
        />
      </div>

      <div class="dyr-service-right">
        <Right
          v-bind="$attrs"
          :downQxValue="downQxValue"
          @getListData="getListData"
          :downList1="downList1"
          :downList4="downList4"
          :rightData="rightData"
          v-on="$listeners"
          @btn="btn"
          @click="btn"
        />
      </div>

    </div>

    <MaskBox
      :stateParams="stateParams"
      :downQxValue="downQxValue"
      :modelType="modelType"
      :changeMask="showMask"
      :mainTable="TableTitle"
      @getTabal="getTabal"
      @getMask="closeMask"
      @btn="btn"
      :chaMask="chaMask"
      :TanleContent="TanleContent"
      :mdtitle="mdtitle"
      :tableTotal="tableTotal"
      :chartsType="chartsType"
      :deviceId="deviceId"
    >
      <NjMaskImg :stateParams="stateParams"></NjMaskImg>
    </MaskBox>
    <bottomNav
      v-on="$listeners"
      @getListData="getListData"
      v-bind="$attrs"
      :downList2="downList2"
      :downList3="downList3"
      :mqData2="mqData2"
      :mqData="mqData"
      :videoData="videoData"
      class="middle-bottomNav"
    />
  </div>
</template>

<script>
import MaskBox from './MaskTool.vue';
import NjMaskImg from './components/NjzlMaskImg.vue';
import Title from './components/title.vue';
import Power from './power.vue';
import Service from './service.vue';
import LastYear from './lastYear.vue';
import Mddle from './middle/index.vue';
import bottomNav from './components/bottomNav.vue';
import Right from './right/index.vue';
import Map from './components/map.vue';
import {
  getNtmj,
  fieldBasic,
  getQxdp,
  getSqxq,
  getZzbt,
  getLsgnq,
  getNtsl,
  getNtbt,
  getSeedlingsByName,
  getPestMonitoringByName,
  getSeedlingNames,
  getAirDateLog,
  getWaterQuality,
  device,
  getAlarmInfo,
  getIdName
} from '../../api/numberApi/index';
export default {
  components: {
    Title,
    Power,
    Service,
    LastYear,
    Mddle,
    bottomNav,
    Right,
    Map,
    MaskBox,
    NjMaskImg
  },
  data () {
    return {
      divList: [],
      address: '',
      videoData:{},
      //警告数据
      tzList: [],
      downQxValue: '',
      //土壤监测点id
      deviceId: '',
      chartsType: '',
      downList1: [],
      downList2: [],
      downList3: [],
      downList4: [],
      //苗期图片
      mqData: [],
      mqData2: [],
      //弹窗标题
      mdtitle: '',
      //弹窗列表数据总数
      tableTotal: 0,
      //MaskBox所需数据
      maskBoxData: {},
      //中间弹窗
      chaMask: false,
      //弹窗类型
      modelType: '',

      rightData: {
        //气象信息数据
        qxData: {},
        //墒情信息数据
        sqxqData: {},
        //水质信息
        szData: {}
      },
      stateParams: {
        title: '粮食功能区',
        count: 15
      },
      TanleContent: [
        {
          xh: '1',
          nt: '农田1',
          czr: '吴世勋',
          jwd: '120.17211,30.25358',
          mj: '722亩',
          zzzl: '水稻'
        },
        {
          xh: '2',
          nt: '农田1',
          czr: '吴世勋',
          jwd: '120.17211,30.25358',
          mj: '722亩',
          zzzl: '水稻'
        }
      ],
      TableTitle: [],
      TableTitle1: [
        { id: 1, prop: 'xh', label: '序号', width: '60', png: false },
        { id: 2, prop: 'fieldName', label: '农田名称', width: '100' },
        { id: 3, prop: 'lessee', label: '承租人', width: '50' },
        // { id: 4, prop: "jwd", label: "经纬度", width: "100", png: false },
        {
          id: 5,
          prop: 'fieldArea',
          label: '面积',
          width: '80'
        },
        {
          id: 6,
          prop: 'plantType',
          label: '种植种类',
          width: '60'
        }
      ],
      TableTitle2: [
        { id: 1, prop: 'xh', label: '序号', width: '60', png: false },
        { id: 2, prop: 'fieldName', label: '农田', width: '70' },
        { id: 3, prop: 'lessee', label: '承租人', width: '50' },
        {
          id: 4,
          prop: 'fieldArea',
          label: '面积',
          width: '80'
        },
        {
          id: 5,
          prop: 'plantType',
          label: '种植种类',
          width: '60'
        },

        {
          id: 6,
          prop: 'subsidyStandard',
          label: '补助标准',
          width: '100'
        },
        {
          id: 7,
          prop: 'plantSubsidy',
          label: '种植补贴',
          width: '100'
        }
      ],
      TableTitle3: [
        { id: 1, prop: 'xh', label: '序号', width: '60', png: false },
        { id: 2, prop: 'principalName', label: '农业主体', width: '70' },
        { id: 3, prop: 'telphone', label: '联系电话', width: '50' },
        {
          id: 4,
          prop: 'totalFieldArea',
          label: '面积',
          width: '80'
        }
      ],

      showMask: true,
      //农田基本情况
      ntjbqk: {
        zmj: '',
        zymj: '',
        gncq: '',
        nyzt: ''
      },
      //弹窗列表参数
      pagData: {
        pageNum: 1,
        pageSize: 10,
        fieldType: ''
      },
      //粮食功能区数据
      lsgnqData: 0
    };
  },
  created () {
    this.address = localStorage.getItem('address');
    this.getData();
    setInterval(this.timeOut(), 30000);
  },
  mounted () {},
  methods: {
    timeOut () {
      getAlarmInfo().then(res => {
        if (res.code == 1) {
          this.tzList = res.data.map(item => {
            return {
              title: item.alarmPosition,
              time: item.alarmTime
            };
          });
        }
      });
    },
    // array需要拆分的数组
    splitArray (array, size) {
      let data = [];
      for (let i = 0; i < array.length; i += size) {
        data.push(array.slice(i, i + size));
      }
      return data;
    },
    //获取下拉框数据
    async getListData (e, type) {
      if (type == 1) {
        //获取所有土壤监测点名称
        await getSqxq(e.value).then(res => {
          if (res.code == 1) {
            this.rightData.sqxqData = res.data;
            // this.$set(this.rightData.sqxqData,'deviceId',e.value)
          }else{
            this.rightData.sqxqData = {};
          }
        });
      } else if (type == 3) {
        //根据监测点名称获取虫情监测信息
        await getPestMonitoringByName(e.deviceId).then(res => {
          if (res.code == 1) {
            this.mqData2 = this.splitArray(res.data, 2);
          }
        });
      } else if (type == 4) {
        this.downQxValue = e.value;
        //获取气象信息
        await getQxdp(e.value).then(res => {
          if (res.code == 1) {
            this.rightData.qxData = res.data;
          }else{
            this.rightData.qxData = {};
          }
        });
      } else if (type == 5) {
        // this.getSeedlingsByName = e.value;
        //获取苗期监测
        await getSeedlingsByName(e.value).then(res => {
          if(res.cpde==1&&res.data[0].videoUrl){
              this.videoData = [ res.data[0] ];
            }else{
              this.videoData= res.data;
            }
        });
      }
      else {
        await getSeedlingsByName(e.label).then(res => {
          if(res.cpde==1&&res.data[0].videoUrl){
              this.videoData = [ res.data[0] ];
            }else{
              this.videoData= res.data;
            }
        });
      }
    },
    //调用接口
    async getData () {
      // 获取所有设备
      await device().then(res => {
        if (res.code == 1) {
          this.divList = res.data.filter(item => {
            if (item.latitude && item.longitude) {
              return item;
            }
          });
        }
      });
      // 获取所有土壤监测点名称
      await getIdName({ category: 2 }).then(res => {
        if (res.code == 1) {
          this.downList1 = res.data.map(item => {
            return {
              value: item.deviceId,
              label: item.deviceName
            };
          });
        }
      });

      await getSeedlingNames().then(res => {
        if (res.code == 1) {
          this.downList2 = res.data.map(item => {
            return {
              label: item,
              value: item
            };
          });
        }
      });
      await getNtmj().then(res => {
        if (res.code == 1) {
        }
      });
      //获取农田基本情况
      // await fieldBasic().then(res => {
      //   if (res.code == 1) {
      //     this.ntjbqk = res.data;
      //   }
      // });
      // 获取农田基本情况
      await fieldBasic().then(res => {
        if (res.code == 1) {
          // this.ntjbqk = res.data[0];
          this.ntjbqk = {
            totalArea: res.data[0].totalArea,
            ownFieldArea: res.data[0].ownArea,
            grainFieldArea: res.data[0].functionalArea,
            agricultureCount: res.data[0].agricultureEntitiesNum,
            typeList: res.data[0].plantingType,
          };
        }
      });
      let dw = '';
      //获取气象站名称和设备id
      await getIdName({ category: 1 }).then(res => {
        if (res.code == 1 && res.data.length) {
          this.downList4 = res.data.map(item => {
            return {
              value: item.deviceId,
              label: item.deviceName
            };
          });
          this.downQxValue = res.data[0].deviceId;
        }
      });
      //获取气象信息
      await getQxdp(this.downQxValue).then(res => {
        if (res.code == 1) {
          this.rightData.qxData = res.data;
        }else{
          this.rightData.qxData = {};
        }
      });
      //获取墒情信息
      if (this.downList1.length) {
        await getSqxq(this.downList1[0].value).then(res => {
          //console.log(res, 'resresres');
          if (res.code == 1) {
            this.rightData.sqxqData = res.data;
          }else{
            this.rightData.sqxqData = {};
          }
        });
      }
      // 获取水质信息
      await getWaterQuality().then(res => {
        if (res.code == 1) {
          this.rightData.szData = res.data;
        }
      });
      //获取粮食功能区数量
      await getLsgnq().then(res => {
        if (res.code == 1 && res.data.length) {
          this.lsgnqData = res.data.length;
        }
      });
      //获取农田总种植补贴
      await getZzbt().then(res => {
        if (res.code == 1) {
          this.$set(
            this.ntjbqk,
            'fieldTotalSubsidy',
            res.data.fieldTotalSubsidy
          );
          this.$set(this.ntjbqk, 'lsgnq', this.lsgnqData);
        }
      });
      //监测点名称获取苗情信息
      if (this.downList2.length && this.downList2[0].value) {
        await getSeedlingsByName(this.downList2[0].value).then(res => {
          if (res.code == 1) {
            if(res.data[0].videoUrl){
              this.videoData = [ res.data[0] ];
            }else{
              this.videoData= res.data;
            }

            // this.mqData = res.data
            this.mqData = this.splitArray(res.data, 2);
          }
        });
      }
      await getIdName({ category: 3 }).then(res => {
        if (res.code == 1 && res.data.length) {
          this.downList3 = res.data.map(item => {
            return {
              ...item,
              label: item.deviceName,
              value: item.deviceId
            };
          });
        }
      });
      //根据监测点名称获取虫情监测信息
      await getPestMonitoringByName(this.downList3[0].value).then(res => {
        if (res.code == 1) {
          this.mqData2 = this.splitArray(res.data, 2);
        }
      });

      // 获取气象日志信息曲线图数据
      await getAirDateLog({ deviceId: this.downQxValue }).then(res => {
        if (res.code == 1) {
          // //console.log(res, '获取气象日志信息曲线图数据');
        }
      });
      //获取虫害告警信息
      // await getPestWarning().then(res => {
      //   if (res.code == 1) {
      //     this.tzList = res.data.map(item => {
      //       return {
      //         title: item.content,
      //         time: item.warningTime
      //       }
      //     })
      //   }
      // })
    },

    //获取弹窗数据
    async getTabal (e, type) {
      let zt = false;
      this.pagData.pageNum = e;
      this.stateParams = type;
      if (type.title == '农田总面积' || type.title == '种植补贴') {
        this.pagData = {
          pageNum: e,
          pageSize: Math.floor(document.body.clientHeight / 100)
        };
      } else if (type.title == '粮食功能区') {
        this.pagData.fieldType = '粮食功能区';
      } else if (type.title == '农业主体') {
        this.pagData.fieldType = '农业主体';
      }
      if (type.title == '种植补贴') {
        this.TableTitle = this.TableTitle2;
        await getNtbt(this.pagData).then(res => {
          if (res.code == 1) {
            this.tableTotal = res.data.total;
            this.TanleContent = res.data.records.map((item, index) => {
              return Object.assign(item, {
                xh: index + 1,
                fieldArea: `${ item.fieldArea }亩`
              });
            });

            zt = true;
          } else {
            zt = false;
          }
        });
      }else if(type.title == '农业主体'){
        this.TableTitle = this.TableTitle3; 
        await getLsgnq(this.pagData).then(res => {
          if (res.code == 1) {
            this.tableTotal = res.data.total;
            this.TanleContent = res.data.records.map((item, index) => {
              return Object.assign(item, {
                xh: index + 1,
                fieldArea: `${ item.totalFieldArea }亩`
              });
            });

            zt = true;
          } else {
            zt = false;
          }
        }); 
      }
       else {
        this.TableTitle = this.TableTitle1;
        await getNtsl(this.pagData).then(res => {
          if (res.code == 1) {
            this.tableTotal = res.data.total;
            this.TanleContent = res.data.records.map((item, index) => {
              let jwd = {
                jwd: item.longitude
                  ? `${ item.longitude }${
                    item.latitude ? `,${ item.latitude }` : ''
                  }`
                  : ''
              };
              return Object.assign(
                item,
                { xh: index + 1, fieldArea: `${ item.fieldArea }亩` },
                jwd
              );
            });
            zt = true;
          } else {
            zt = false;
          }
        });
      }

      return zt;
    },
    //打开弹窗
    async btn (e, type, fieldType) {
      if (fieldType) {
        this.modelType = type;
        this.pagData.fieldType = fieldType;
        this.getTabal(1, fieldType).then(err => {
          if (err) {
            this.chaMask = e;
          }
        });
      } else {
        this.chaMask = e;
        this.modelType = type.type;
        this.mdtitle = type.title;
        this.chartsType = type.chartType;
        if (type.id) {
          this.deviceId = type.id;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dyr-service {
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  // background-image: url(../../assets/drying-page/bcg-img.png);
  background-color: #1d2531;
  background-size: cover;
  background-repeat: no-repeat;

  .dyr-service-title {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
  }

  .dyr-service-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dyr-service-left {
      width: companyW(482vw);
      height: companyH(968vh);
      margin-left: companyW(20vw);

      .dyr-service-service {
        margin-top: companyH(21vh);
      }
    }

    .dyr-service-right {
      margin-right: companyW(20vw);
      height: companyH(978vh);
    }

    .middle-map {
      width: companyW(820vw);
      height: companyH(550vh);
      position: absolute;
      top: companyH(235vh);
      left: companyW(550vw);
      // background-color: #f00;
    }
  }

  .dyr-service-middle {
  }

  .middle-bottomNav {
    position: absolute;
    bottom: companyH(19vh);
    left: companyW(497vw);
  }
}
</style>
