<template>
  <div class="storage">
     <div class="storage-storage">
         <div class="storage-storage-item">
            <div class="storage-item-desc">
                 <span class="item-desc-proportion">占比：50%</span>
                 <span class="item-desc-storage">已储存：250 吨</span>
            </div>
           <div class="storage-item-echart">
             <Echart :option="option"/>
           </div>
         </div>
         <div class="storage-storage-desc">
           <span>500吨</span>
           <span>储存能力</span>
         </div>
     </div>
     <div class="storage-storage">
         <div class="storage-storage-item">
            <div class="storage-item-desc">
                 <span class="item-desc-proportion">占比：57%</span>
                 <span class="item-desc-storage">正在烘干：57 吨</span>
            </div>
           <div class="storage-item-echart">
             <Echart :option="DryOption"/>
           </div>
         </div>
         <div class="storage-storage-desc">
           <span>100 吨</span>
           <span>烘干能力</span>
         </div>
     </div>
  </div>
</template>

<script>
import Echart from '../../../components/echarts/index.vue';
import option from '../config/storage.js';
import DryOption from '../config/dry.js';
export default {
  components:{
    Echart
  },
  data(){
    return {
      option,
      DryOption
    };
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.storage{
  width: companyW(650vw);
  height: companyH(355vh);
  margin: 0 auto;
  margin-top: companyH(119vh);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .storage-storage{
    width:companyW(280vw);
    height: companyH(343vh);
    .storage-storage-item{
      width: companyH(280vh);
      height: companyH(280vh);
      position: relative;
      .storage-item-desc{
        position: absolute;
        top: companyH(113vh);
        left: companyW(48vw);
        z-index: 999999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .item-desc-proportion{
          height: companyH(18vh);
          font-size: companyH(12vh);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
        .item-desc-storage{
          margin-top: companyH(16vh);
          height:companyH(24vh);
          font-size: companyH(24vh);
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      .storage-item-echart{
        width: companyH(280vh);
        height: companyH(280vh);
      }
    }
    .storage-storage-desc{
      margin-top: companyH(8vh);
      width: companyW(100vw);
      height: companyH(55vh);
      margin-left:companyW(80vw);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span:nth-child(1){
        font-size: companyH(30vh);
        font-family: DIN;
        font-weight: 400;
        color: #FFFFFF;
      }
      span:nth-child(2){
        margin-top: companyH(11vh);
        font-size: companyH(18vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: companyH(13vh);
      }
    }
  }
}
</style>
