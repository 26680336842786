<template>
  <div class="mapModelBox">
    <div class="mapModelBox-cbl">
      <div class="NavList">
        <div class="NavOne currentNav" v-for="item in NavList" :key="item.id" @click="changeNav(item.id)">
          <div class="NavTitle">{{ item.label }}</div>
          <div class="NavTitle">
            <!-- <span>{{ item.value }}</span> -->
            <!-- <span class="NavSpan">{{ item.unit }}</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mapModelBox-video">
      <video class="Box-video" id="mse" autoplay=true playsinline controls="controls">
        <source src="实机视频地址" type="video/mp4">
        你的浏览器不支持Video标签
      </video>
    </div>
    <div class="mapModelBox-kzt">
      <el-radio-group v-model="tabPosition" class="radio-box">
        <el-radio-button label="top">云台</el-radio-button>
        <el-radio-button label="right">回放</el-radio-button>
      </el-radio-group>
      <div class="nr-box">
        <el-calendar v-if="tabPosition == 'right'" v-model="value">
        </el-calendar>
        <!-- <el-date-picker
        v-if="tabPosition=='right'"
      v-model="value"
      type="date"
      placeholder="选择日期">
    </el-date-picker> -->

        <div v-else-if="tabPosition == 'top'">
          <div class="yuan">
            <div class="yuan-item"></div>
            <div class="yuan-item"></div>
            <div class="yuan-item"></div>
            <div class="yuan-item"></div>
          </div>
          <div class="kzq">
            <div class="kzq-item">
              <div class="kzq-add"></div>
              <div class="kzq-text">变倍</div>
              <div class="kzq-reduce"></div>
            </div>
            <div class="kzq-item">
              <div class="kzq-add"></div>
              <div class="kzq-text">变焦</div>
              <div class="kzq-reduce"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: new Date(),
      NavList: [
        {
          label: '摄像机1',
          value: 'value',
          unit: 'unit'
        },
        {
          label: '摄像机2',
          value: 'value',
          unit: 'unit'
        },
        {
          label: '摄像机3',
          value: 'value',
          unit: 'unit'
        },
      ],
      tabPosition: 'top'
    }
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.mapModelBox {
  width: 95%;
  height: companyH(650vh);
  margin: 0 auto;
  //   background-color: #fff; 
  display: flex;
  justify-content: space-around;

  .mapModelBox-cbl {
    width: companyW(180vw);
    height: companyH(650vh);

    .NavList {

      display: flex;
      flex-direction: column;
      margin: companyH(40vh) companyW(10vw) 0 0;

      .NavOne {
        width: companyW(165vw);
        height: companyH(57vh);
        background-image: url(../../../assets/njImgs/toolNav.png);
        background-size: cover;
        margin-bottom: companyH(10vh);
        opacity: 0.3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: companyH(4vh) companyW(10vw);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #33f8f8;
        cursor: pointer;

        .NavTitle {
          margin-left: companyW(10vw);
          font-size: companyH(15.2vh);
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #33f8f8;

          .NavSpan {
            font-size: companyH(12vh);
            margin-left: companyW(5vw);
            color: #33f8f8;
          }
        }
      }
    }

    .NavOne:hover {
      opacity: 0.7 !important;
      transition: all 0.5s;
    }

    .currentNav {
      opacity: 1 !important;
    }
  }

  .mapModelBox-video {
    width: companyW(800vw);
    height: companyH(550vh);
    margin: companyH(50vh) 0;

    .Box-video {
      width: companyW(800vw);
      height: companyH(550vh);
    }

    // background-color: #fcc;
  }

  .mapModelBox-kzt {
    width: companyW(350vw);
    height: companyH(650vh);

    .radio-box {
      margin: companyH(50vh) 0 0 0;
      width: companyW(350vw);
      height: companyH(60vh);

      ::v-deep {
        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
          background-color: rgb(7, 176, 133) !important;
          color: #fff;
        }

        .el-radio-button {
          width: 49% !important;
          // border: 1px solid rgb(7, 176, 133);
        }

        .el-radio-button:first-child .el-radio-button__inner {
          width: 100%;
          background-color: rgb(37, 44, 50);
          border: 1px solid rgb(7, 176, 133);
        }

        .el-radio-button:last-child .el-radio-button__inner {
          width: 100%;
          background-color: rgb(37, 44, 50);
          border: 1px solid rgb(7, 176, 133);
        }

      }
    }

    .nr-box {
      width: companyW(350vw);
      height: companyH(450vh);

      .yuan {
        margin: companyH(20vh) auto 0;
        width: companyW(200vw);
        height: companyW(200vw);
        transform: rotate(45deg);
        background-color: #fff;
        overflow: hidden;
        border-radius: 100%;
        display: flex;
        flex-wrap: wrap;

        .yuan-item {
          width: companyW(100vw);
          height: companyW(100vw);

          // width: ;
        }
      }

      .kzq {
        margin: companyH(20vh) auto 0;
        width: companyW(200vw);
        height: companyW(200vh);

        .kzq-item {
          display: flex;
          justify-content: space-around;
          margin: companyH(20vh) auto 0;
          width: companyW(200vw);
          height: companyW(80vh);

          .kzq-add {
            width: companyW(30vw);
            height: companyW(30vw);
            background-color: #fff;
          }

          .kzq-reduce {
            width: companyW(30vw);
            height: companyW(30vw);
            background-color: #fff;
          }

          .kzq-text {
            height: companyW(30vw);
            line-height: companyW(25vw);
            font-size: companyW(20vw);
            font-weight: bold;
          }
        }

      }
    }

    ::v-deep {
      .el-calendar{
        background-color: #000;
        
      }
      .el-calendar__title{
        color: #fff;
      }
      .custom-calendar .el-calendar-table .el-calendar-day {
        width: companyW(350vw);
      height: companyH(450vh);
     
      }
      .current {
        color: #fff;
      }
      .prev{
        color: rgb(83, 83, 83);
      }
      .next{
        color: rgb(83, 83, 83);
      }
      .el-calendar-table td{
        border: 0;
      }
      .is-selected{
        background-color: rgb(36, 136, 237);
      }
      .el-calendar-table .el-calendar-day{
        width:  companyH(40vh);
        height: companyH(40vh);
        line-height: companyH(40vh);
        text-align: center;
        padding: 0;
       
      }
      .el-calendar-day:hover{
        background-color:  inherit;
      }
      .el-button--mini{

        padding: companyH(5vh) companyW(5vw) !important;
        background-color: #000;
        border: none;
        color: #fff;
      }
      .el-calendar-table thead th{
        color: #fff;
      }
    }

    // background-color: #cfc;
  }
}</style>