<template>
  <div class="bottomNav">
    <div class="bottomNav-item">
  
      <div v-if="isImg">
          <moduleTitle v-bind="$attrs" v-on="$listeners"  title="苗期监测" :downList="downList2" type="xlimg" ></moduleTitle>
      <div class="lbt">
        <el-carousel :initial-index="0" @change = "((pre,next)=>{carousel1(pre,next,1)})" indicator-position="none" height="17vh" :interval="5000" arrow="always">
        <el-carousel-item v-for="(item,index) in mqData" :key="index">
          <div class="img-box">
            <div class="img-box-item"> 
              <img class="img-box-item-img" :src="item[0].seedlingsPicture"></img>
              <div class="img-box-item-time">{{ item[0].monitoringDate }}</div>
            </div>
            <div class="img-box-item">
              <img class="img-box-item-img" v-if="item.length>1" :src="item[1].seedlingsPicture"></img>
              <div class="img-box-item-time" v-if="item.length>1">{{ item[1].monitoringDate }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="btm-progress">
        <el-progress stroke-width="5" color="#65ebca" :percentage="percentage1" status="success">
        </el-progress>
      </div>
      </div>
      </div>
      <div v-else>
      <moduleTitle v-bind="$attrs" v-on="$listeners"  title="苗期监测" :downList="downList2" type="xl" ></moduleTitle>
      <div class="videoBox" :key="key">
        <div class="videoItem">
          <video ref="videoPlayer1" style="width: 100%;"  class="video-js">
          <source v-if="videosrc" :src="videosrc" type="application/x-mpegURL"/>
        </video>
        </div>
        <div class="videoItem">
          <video  ref="videoPlayer2" style="width: 100%;"  class="video-js">
          <source  v-if="videosrc" :src="videosrc" type="application/x-mpegURL"/>
        </video>
        </div>
      </div>
    </div>
    </div>
    <div class="bottomNav-item">
      <moduleTitle  v-on="$listeners"  title="虫情监测" :downList="downList3" type="xl"></moduleTitle>
      <div class="lbt">
        <el-carousel :initial-index="0" @change = "((pre,next)=>{carousel1(pre,next,2)})" indicator-position="none" height="17vh" :interval="5000" arrow="always">
        <el-carousel-item v-for="(item,index1) in mqData2" :key="index1">
          <!-- <h3>{{ item }}</h3> -->
          <div class="img-box">
            <div class="img-box-item">
              <img class="img-box-item-img" :src="item[0].monitoringPicture"></img>
              <div class="img-box-item-time">{{ item[0].monitoringDate }}</div>
            </div>
            <div class="img-box-item">
              <img class="img-box-item-img" v-if="item.length>1"  :src="item[1].monitoringPicture"></img>
              <div class="img-box-item-time" v-if="item.length>1">{{ item[1].monitoringDate }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="btm-progress">
        <el-progress stroke-width="5" color="#65ebca" :percentage="percentage2" status="success">
        </el-progress>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Image } from 'echarts/lib/util/graphic';
import moduleTitle from '../components/moduleTitle.vue';
import "videojs-contrib-hls";
import Videojs from "video.js";
import "video.js/dist/video-js.css";

export default {
  data() {
    return {
      isImg:false,
      videosrc: "",
      list:[
      
      ],
      percentage1:0,
      list2:[
       
      ],
      percentage2:0,
      key:0
      
    };

  }, 
  props:{
    mqData:{
      type:Array,
      default:[]
    },
    mqData2:{
      type:Array,
      default:[]
    },
    downList2:{
      type:Array,
      default:[]
    },
    downList3:{
      type:Array,
      default:[]
    },
    videoData:null,
  },
  components: {
    moduleTitle: moduleTitle,
    Image
},
  mounted(){
    //初始化进度条进度
    // this.percentage1=(1/this.mqData.length)*100
    // this.percentage2=(1/this.mqData2.length)*100
  },
  watch:{
    mqData:{
      handler(newData,oldData){
        this.percentage1=(1/newData.length)*100
      },
      deep:true
    },
    mqData2:{
      handler(newData,oldData){
        this.percentage2=(1/newData.length)*100
      },
      deep:true
    },
    videoData:{
      handler(newData,oldData){
        this.getVideo()
      },
      deep:true
    },
    

  },
  methods: {
    //获取视频
    getVideo(){
      this.key += 1 
      if(this.videoData[0].videoUrl){
        this.isImg = false
      }else{
        this.isImg = true
        return
      }
      this.videosrc=this.videoData[0].videoUrl
      
      // console.log(this.videosrc,'this.videosrc');
          this.$nextTick(() => {
            Videojs(this.$refs.videoPlayer1, {
              muted: true, //muted : true/false 是否静音
              controls: true,
              BigplayButton: false,
              autoplay: true,
              fluid: true,//Video.js播放器将具有流畅的大小。换句话说，它将扩展以适应其容器。
              // aspectRatio: "10:6",
              aspectRatio: "16:9",
              fullscreen: {
                options: {
                  navigationUI: "hide",
                },
              },
              techOrder: ["html5"], // 兼容顺序
              flvjs: {
                mediaDataSource: {
                  isLive: false,
                  cors: true,
                  withCredentials: false,
                },
              },
            });
            Videojs(this.$refs.videoPlayer2, {
              muted: true, //muted : true/false 是否静音
              controls: true,
              BigplayButton: false,
              autoplay: true,
              fluid: true, //Video.js播放器将具有流畅的大小。换句话说，它将扩展以适应其容器。
              // aspectRatio: "10:6",
              aspectRatio: "16:9",
              fullscreen: {
                options: {
                  navigationUI: "hide",
                },
              },
              techOrder: ["html5"], // 兼容顺序
              flvjs: {
                mediaDataSource: {
                  isLive: false,
                  cors: true,
                  withCredentials: false,
                },
              },
            });
          });
    },
    //轮播图1切换时
    carousel1(e,n,i){
      if(i==1){
        this.percentage1=((e+1)/this.mqData.length)*100
      }else if(i == 2){
        this.percentage2=((e+1)/this.mqData2.length)*100
      }
   
    }
  },
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}

.bottomNav {
  position: absolute;
  left: 0;
  width: companyW(924vw);
  height: companyH(250vh);
  justify-content: space-between;
  display: flex;

  .bottomNav-item {
    position: relative;
    width: 49%;
    height: companyH(250vh);
    // border: companyW(1vw) solid;
    background-color: rgba(0,0,0,.7);
    .lbt{
      position: absolute;
      top: companyH(30vh);
      width: 100%;
      height: companyH(220vh);
      // background-color: #fff;
      .img-box{
      top: companyH(30vh);
      width:  100%;
      height: companyH(250vh);
      display: flex;
      justify-content: space-around;
      .img-box-item{
        .img-box-item-img{
        margin-top: companyH(20vh);
        width: companyW(200vw);
        height: companyH(120vh);
        }
        .img-box-item-time{
          margin-top: companyH(10vh);
          text-align: center;
          font-size: companyH(10vh);
          color: #fff;
        }
      }
      }
      .btm-progress{
        padding-left: companyW(40vw);
        width: companyW(360vw);
      height: companyH(20vh);
      }
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      // background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
      // background-color: #d3dce6;
    }
    ::v-deep{
      .el-icon-circle-check:before{
      content: none;
      display: none;
    }
    .el-progress-bar__outer{
      background-color: #14715b !important;
    }
    } 
  }
  .videoBox{
    position: absolute;
    top: companyH(40vh);
    width: calc(100% - companyH(20vh)) ;
    padding: companyH(10vh);
    height: companyH(180vh);
    display: flex;
   
    .videoItem{
      flex: 1;
      background-color: #fff;
      margin: companyH(10vh) companyW(5vw);
    }
  }
}</style>
