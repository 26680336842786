<template>
      <div class="ck-box" v-if="loading">
        <div class="Loading">Loading...</div>
     </div>
  <div  class="ck-box" v-else>
    <div class="ca-title" >{{ mdtitle }}</div>
    <div class="ck-echarts-flex" >
      <div class="ca-echarts">
        <Echart :option="option"></Echart>
      </div>
      <div class="ca-text" v-if="mdtitle == '气象信息'">
        <div class="ca-text-item">
          <div class="ca-text-item-title">累计降雨量</div>
          <div class="ca-text-item-data">{{ Math.floor(this.qxls[0].totalPrecipitation)  }}mm</div>
          <div class="ca-text-item-compare">较去年同期
            <span class="compare-down" v-if="this.qxls[0].precipitationSameRate.includes('-')">{{ this.qxls[0].precipitationSameRate }}↓</span>
            <span class="compare-up" v-else>{{ this.qxls[0].precipitationSameRate }}↑</span>
          </div>
        </div>
        <div class="ca-text-item">
          <div class="ca-text-item-title">积温</div>
          <div class="ca-text-item-data">{{ this.qxls[4].accumulateTemperature?this.qxls[4].accumulateTemperature.toFixed(2):'' }}℃</div>
          <div class="ca-text-item-compare">较去年同期
            <span class="compare-down" v-if="this.qxls[4].accumulateTemperatureSameRate.includes('-')">{{ this.qxls[4].accumulateTemperatureSameRate }}↓</span>
            <span class="compare-up" v-else>{{ this.qxls[4].accumulateTemperatureSameRate }}↑</span>
          </div>
        </div>
        <div class="ca-text-item">
          <div class="ca-text-item-title">日较差</div>
          <div class="ca-text-item-data">{{ this.qxls[3].dailyAmplitude?this.qxls[3].dailyAmplitude.toFixed(2):'' }}℃</div>
          <div class="ca-text-item-compare">较去年同期
            <span class="compare-down" v-if="this.qxls[3].dailyAmplitudeSameRate.includes('-')">{{ this.qxls[3].dailyAmplitudeSameRate }}↓</span>
            <span class="compare-up" v-else>{{ this.qxls[3].dailyAmplitudeSameRate }}↑</span>
          </div>
        </div>
        <div class="ca-text-item">
          <div class="ca-text-item-title">35℃以上高温天数</div>
          <div class="ca-text-item-data">{{ this.qxls[1].highTemperatureDays }}天</div>
          <div class="ca-text-item-compare">较去年同期
            <span class="compare-down" v-if="this.qxls[1].highTemperatureSameRate.includes('-')">{{ this.qxls[1].highTemperatureSameRate }}↓</span>
            <span class="compare-up" v-else>{{ this.qxls[1].highTemperatureSameRate }}↑</span>
          </div>
        </div>
        <div class="ca-text-item">
          <div class="ca-text-item-title">0℃以下低温天数</div>
          <div class="ca-text-item-data">{{ this.qxls[2].lowTemperatureDays }}天</div>
          <div class="ca-text-item-compare">较去年同期
            <span class="compare-down" v-if="this.qxls[2].lowTemperatureSameRate.includes('-')">{{ this.qxls[2].lowTemperatureSameRate }}↓</span>
            <span class="compare-up" v-else>{{ this.qxls[2].lowTemperatureSameRate }}↑</span>
          </div>
        </div>
      </div>
      <div class="ca-text" v-else-if="mdtitle == '虫情种类'">
        <div class="ca-text-item">
          <div class="ca-text-item-title">日虫害数量</div>
          <div class="ca-text-item-data">{{ this.qxls[0].dayPestNum }}只</div>
          <div class="ca-text-item-compare">较去年同期
            <span class="compare-down" v-if="this.qxls[0].dayPestSameRate.includes('-')">{{ this.qxls[0].dayPestSameRate }}↓</span>
            <span class="compare-up" v-else>{{ this.qxls[0].dayPestSameRate }}↑</span>

          </div>
        </div>
        <div class="ca-text-item">
          <div class="ca-text-item-title">周虫害数量</div>
          <div class="ca-text-item-data">{{ this.qxls[1].weeksPestNum }}只</div>
          <div class="ca-text-item-compare">较去年同期
            <span class="compare-down" v-if="this.qxls[1].weeksPestSameRate.includes('-')">{{ this.qxls[1].weeksPestSameRate }}↓</span>
            <span class="compare-up" v-else>{{ this.qxls[1].weeksPestSameRate }}↑</span>
          </div>
        </div>
        <div class="ca-text-item">
          <div class="ca-text-item-title">月虫害数量</div>
          <div class="ca-text-item-data">{{ this.qxls[2].monthPestNum }}只</div>
          <div class="ca-text-item-compare">较去年同期
            <span class="compare-down" v-if="this.qxls[2].monthPestSameRate.includes('-')">{{ this.qxls[2].monthPestSameRate }}↓</span>
            <span class="compare-up" v-else>{{ this.qxls[2].monthPestSameRate }}↑</span>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>
<script>
import { Loading } from 'element-ui';
import Echart from '../../../components/echarts/index.vue'
import { getAirDateLog, getAirHistory, getPestGraph, getPestHistory,getLogAll } from '../../../api/numberApi/index'
export default {
  components: {
    Echart: Echart
  },
  data() {
    return {
      //气象历史信息
      qxls: [],
      loading:false,
      option: {
        title: {
          left: '3%',
          text: '历史虫害',
          textStyle: {
            color: '#fff',
            fontWeight: 'normal',
            fontSize: 16,
          }
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['最高温度', '平均温度', '最底温度'],
          textStyle: {
            color: '#fff',
            fontWeight: 'normal',
            fontSize: 14,

          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#53668aFF',
              opacity: 0
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#1a222bFF'],
              width: 1,
              type: 'solid'
            }
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#1a222bFF'],
              width: 1,
              type: 'solid'
            }
          }

        },
        series: [
          {
            name: '最高温度',
            type: 'line',
            // stack: 'Total',
            symbol: 'none',
            data: [],
            itemStyle: {
              normal: {
                lineStyle: {
               
                }
              }
            },
            smooth: true
          },
          {
            symbol: 'none',
            name: '平均温度',
            type: 'line',
            // stack: 'Total',
            data: [],
            itemStyle: {
              normal: {
                lineStyle: {
                 
                }
              }
            },
            smooth: true
          },
          {
            symbol: 'none',
            name: '最低温度',
            type: 'line',
            // stack: 'Total',
            data: [],
            itemStyle: {
              normal: {
                lineStyle: {
            
                }
              }
            },
            smooth: true
          }
        ]
      },
      options:[]
    }
  },
  props: {
    mdtitle: {
      type: String,
      default: '',
    },
    chartsType:{
      type: String,
      default: '',
    },
    deviceId:{
      type: String,
      default: '',
    },
    downQxValue:{
      type: String,
      default: '',
    },
  },
  mounted() {
    //获取接口信息
    this.getQx()

  },
  methods: {
    async getQx() {

      this.loading = true
      let obj
      let date = new Date();
      let list = this.getDateRange(date, 30, true)
      // console.log("获取下一个月日期范围：\n开始日期:"+list[0]+";\n结束日期:"+list[1]);
      obj = {
        begin: list[0],
        end: list[1]
      }
      //获取气象信息
      if (this.mdtitle == '气象信息') {
        this.option.title.text = ''
        // 获取最近个月的数据

        await getAirDateLog({deviceId:this.downQxValue}).then(res => {
          if (res.code == 1) {
            //console.log(res, 'res');
            // let list = res.data.dateList.split(',')
            this.option.xAxis.data = res.data.map(item => {
              return item.date.slice(5, 10).replace('-', '/')
            })
            let lx = ['temperatureMax','temperatureAvg','temperatureMin',
            'humidityMax','humidityAvg','humidityMin',
            'lightMax','lightAvg','lightMin',
            'precipitationMax','precipitationAvg','precipitationMin',]
            lx.forEach((item,index)=>{
              this.options[index] =res.data.map(items => {
                if(items[item]){
                  return items[item]
                }else{
                  return 0
                }
            })
            })
            //console.log(this.options,'this.options');
            switch(this.chartsType){
              case 'kqwd':
              this.option.title.text = '空气温度'
              this.option.series[0].data = this.options[0]
              this.option.series[1].data = this.options[1]
              this.option.series[2].data = this.options[2]
              this.option.series[0].name = '最高温度'
              this.option.series[1].name = '平均温度'
              this.option.series[2].name = '最低温度'
              this.option.legend.data = ['最高温度', '平均温度', '最低温度']
              break
              case 'kqsd':
              this.option.title.text = '空气湿度'
              this.option.series[0].data = this.options[3]
              this.option.series[1].data = this.options[4]
              this.option.series[2].data = this.options[5]
              this.option.series[0].name = '最高湿度'
              this.option.series[1].name = '平均湿度'
              this.option.series[2].name = '最低湿度'
              this.option.legend.data = ['最高湿度', '平均湿度', '最低湿度']
              break
              case 'gzqd':
              this.option.title.text = '光照强度'
              this.option.series[0].data = this.options[6]
              this.option.series[1].data = this.options[7]
              this.option.series[2].data = this.options[8]
              this.option.series[0].name = '最高光照强度'
              this.option.series[1].name = '平均光照强度'
              this.option.series[2].name = '最低光照强度'
              this.option.legend.data = ['最高光照强度', '平均光照强度', '最低光照强度']
              break
              case 'jsl':
              this.option.title.text = '降水量'
              this.option.series[0].data = this.options[9]
              this.option.series[1].data = this.options[10]
              this.option.series[2].data = this.options[11]
              this.option.series[0].name = '最高降水量'
              this.option.series[1].name = '平均降水量'
              this.option.series[2].name = '最低降水量'
              this.option.legend.data = ['最高降水量', '平均降水量', '最低降水量']
              break
            }
          }
        })
        await getAirHistory({deviceId:this.deviceId}).then(res => {
          if (res.code == 1) {
            this.qxls = res.data
          }
        })
        this.loading = false
      }
      else if (this.mdtitle == '虫情种类') {
        this.option.title.text = '虫情种类'
        getPestGraph(obj).then(res => {
          if (res.code == 1) {
            this.option.xAxis.data = res.data.map(item => {
              return item.date.slice(5, 10).replace('-', '/')
            })
            let legend = ['害虫种类数量','害虫数量']
           
       
            let data1 = res.data.map(item => {
              return item.pestLevelCount?item.pestLevelCount:0
            })
            let data2 = res.data.map(item => {
              return item.pestQuantity?item.pestQuantity:0
            })
              this.option.series[0] = {
                symbol: 'none',
                  name: '害虫种类数量',
                  type: 'line',
                  stack: 'Total',
                  data: data1
              }
              this.option.series[1] = {
                symbol: 'none',
                  name: '害虫数量',
                  type: 'line',
                  stack: 'Total',
                  data: data2
              }
           
           
          
            this.option.legend.data = legend
          }
        })
        await getPestHistory().then(res => {
          if (res.code == 1) {
            this.qxls = res.data
          }
        })
        this.loading = false
      }else if(this.mdtitle == '墒情信息'){
        this.option.title.text = ''
        // 获取最近个月的数据
        await getLogAll({deviceId:this.deviceId}).then(res => {
          if (res.code == 1) {
            //console.log(res, 'res');
            // let list = res.data.dateList.split(',')
            this.option.xAxis.data = res.data.map(item => {
              return item.date.slice(5, 10).replace('-', '/')
            })
            let lx = ['temperatureMax','temperatureAvg','temperatureMin',
            'humidityMax','humidityAvg','humidityMin',
            'phMax','phAvg','phAvg',
            'ecMax','ecAvg','ecMin',]
            lx.forEach((item,index)=>{
              this.options[index] =res.data.map(items => {
                if(items[item]){
                  return items[item]
                }else{
                  return 0
                }
            })
            })
            switch(this.chartsType){
              case 'trwd':
              this.option.title.text = '土壤温度'
              this.option.series[0].data = this.options[0]
              this.option.series[1].data = this.options[1]
              this.option.series[2].data = this.options[2]
              this.option.series[0].name = '最高温度'
              this.option.series[1].name = '平均温度'
              this.option.series[2].name = '最低温度'
              this.option.legend.data = ['最高温度', '平均温度', '最低温度']
              break
              case 'trsd':
              this.option.title.text = '土壤湿度'
              this.option.series[0].data = this.options[3]
              this.option.series[1].data = this.options[4]
              this.option.series[2].data = this.options[5]
              this.option.series[0].name = '最高湿度'
              this.option.series[1].name = '平均湿度'
              this.option.series[2].name = '最低湿度'
              this.option.legend.data = ['最高湿度', '平均湿度', '最低湿度']
              break
              case 'trph':
              this.option.series[0].data = this.options[6]
              this.option.series[1].data = this.options[7]
              this.option.series[2].data = this.options[8]
              this.option.series[0].name = '最高土壤ph'
              this.option.series[1].name = '平均土壤ph'
              this.option.series[2].name = '最低土壤ph'
              this.option.legend.data = ['最高土壤ph', '平均土壤ph', '最低土壤ph']
              break
              case 'trec':
              this.option.title.text = '土壤EC'
              this.option.series[0].data = this.options[9]
              this.option.series[1].data = this.options[10]
              this.option.series[2].data = this.options[11]
              this.option.series[0].name = '最高土壤ec'
              this.option.series[1].name = '平均土壤ec'
              this.option.series[2].name = '最低土壤ec'
              this.option.legend.data = ['最高土壤ec', '平均土壤ec', '最低土壤ec']
              break
            }
          }
        })
        this.loading = false
      }
    },
    formateDate(time) {
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let day = time.getDate()

      if (month < 10) {
        month = '0' + month
      }

      if (day < 10) {
        day = '0' + day
      }

      return year + '-' + month + '-' + day + ''

    },

    getDateRange(dateNow, intervalDays, bolPastTime) {
      let oneDayTime = 24 * 60 * 60 * 1000;
      let list = [];
      let lastDay;
      if (bolPastTime == true) {
        lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime);
        list.push(this.formateDate(lastDay));
        list.push(this.formateDate(dateNow));
      } else {
        lastDay = new Date(dateNow.getTime() + intervalDays * oneDayTime);
        list.push(this.formateDate(dateNow));
        list.push(this.formateDate(lastDay));
      }
      return list;
    }
  }
}
</script>
<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.ck-box {
  width: 95%;
  height: companyH(500vh);
  margin: companyH(300vh) auto 0;
  padding: companyH(30vh) 0;
  .Loading{
    width: 100%;
    height: companyH(500vh);
    line-height: companyH(400vh);
    color: #05AD85;
    text-align: center;
    font-size: companyW(30vw);

  }
  .ca-title {
    width: 100%;
    height: companyH(40vh);
    line-height: companyH(40vh);
    text-align: center;
    font-size: companyW(25vw);
    font-weight: bold;
    color: #fff;
  }

  .ck-echarts-flex {
    display: flex;
    width: 100%;
    height: companyH(400vh);
    justify-content: space-between;

    .ca-echarts {
      flex: 3;
    }

    .ca-text {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      padding: companyH(30vh) 0;

      // justify-content: space-around;
      // background-color: #fff;
      .ca-text-item {
        width: 50%;
        height: companyH(80vh);

        .ca-text-item-title {
          color: #999999FF;
          font-size: companyW(10vw);
          margin-bottom: companyH(5vh);
        }

        .ca-text-item-data {
          color: #fff;
          font-weight: bold;
          font-size: companyW(20vw);
          margin-bottom: companyH(5vh);
        }

        .ca-text-item-compare {
          color: #999999FF;
          font-size: companyW(10vw);
          margin-bottom: companyH(5vh);

          .compare-up {
            color: #e04b51FF;
            font-size: companyW(10vw);
          }

          .compare-down {
            color: #309630FF;
            font-size: companyW(10vw);
          }
        }
      }
    }
  }

}
</style>