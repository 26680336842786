<template>
  <div class="dry-power">
    <moduleTitle title="农田基本情况"></moduleTitle>
    <div class="dry-power-content">
      <div class="number-content">
        <div class="power-item">
          <div class="item-box">
            <div class="img m1"></div>
            <div class="numb">
              <div class="nf">
                {{ parseInt(ntjbqk ? ntjbqk.totalArea : 0) }}
              </div>
              <div class="mj">总农田面积(亩)</div>
            </div>
          </div>
        </div>
        <div class="power-item">
          <div class="item-box">
            <div class="img m2"></div>
            <div class="numb">
              <div class="nf">
                {{ parseInt(ntjbqk ? ntjbqk.ownFieldArea : 0) }}
              </div>
              <div class="mj">永久性基本农田面积(亩)</div>
            </div>
          </div>
        </div>
        <div class="power-item">
          <div class="item-box">
            <div class="img m3"></div>
            <div class="numb">
              <div class="nf">
                {{ parseInt(ntjbqk ? ntjbqk.grainFieldArea : 0) }}
              </div>
              <div class="mj">粮食功能产区(亩)</div>
            </div>
          </div>
        </div>
        <div class="power-item">
          <div class="item-box">
            <div class="img m4"></div>
            <div class="numb">
              <div class="nf">
                {{ parseInt(ntjbqk ? ntjbqk.agricultureCount : 0) }}
              </div>
              <div class="mj">农业主体(个)</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        主要种植种类：
        <el-tooltip
          class="item"
          effect="dark"
          :content="ntjbqk.typeList"
          placement="top-start"
        >
          <span> {{ ntjbqk.typeList }}</span>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from './components/moduleTitle.vue'
export default {
  components: {
    moduleTitle
  },
  props: {
    ntjbqk: {
      type: Object,
      default: {
        agricultureCount: 0,
        grainFieldArea: 0,
        ownFieldArea: 0,
        totalArea: 0,
        typeList: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  position: relative;
  width: companyW(455vw);
  height: companyH(292vh);
  background: rgba(0, 0, 0, 0.7);
  // border: companyW(1vw) solid;

  /* opacity: 0.74; */
  .dry-power-content {
    position: absolute;
    top: companyH(40vh);

    /* padding-top: companyH(5vh); */
    .number-content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .power-item {
        width: 49%;

        .item-box {
          display: flex;

          .img {
            width: companyH(50vh);
            height: companyH(50vh);
            margin: companyH(30vh) 0 0 companyH(40vh);
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          .nf {
            font-size: companyH(20vh);
            color: #e0e5ee;
            margin-bottom: companyH(3vh);
          }

          .mj {
            font-size: companyH(20vh);
            color: #e0e5ee;
          }

          .m1 {
            background-image: url('../../assets/number-page/tiandi.png');
          }

          .m2 {
            background-image: url('../../assets/number-page/ziyou.png');
          }

          .m3 {
            background-image: url('../../assets/number-page/liangshi.png');
          }

          .m4 {
            background-image: url('../../assets/number-page/nongye.png');
          }

          .numb {
            margin: companyH(30vh) 0 0 companyH(10vh);
          }
        }
      }
    }

    .bottom {
      max-width: companyW(400vw);
      margin-top: companyH(30vh);
      padding-left: companyH(50vh);
      font-size: companyH(20vh);
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
