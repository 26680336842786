<template>
  <div class="botton-box">
    <div class="bar">
      <div class="title-echarts">病虫害种类及数量</div>
      <Echart :option="option"></Echart>
    </div>

    <!-- <div class="pie">
      <div class="title-echarts">病虫害种类占比</div>
      <Echart :option="option2"></Echart>
    </div> -->
  </div>
</template>
<script>
import Echart from '../../../components/echarts/index.vue';
import { getChxx } from '../../../api/numberApi/index';
export default {
  data() {
    return {
      option: {
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            textStyle: {
              color: '#fff',
            },
            interval: 0,
            rotate: -20,
          },
        },
        grid: {
          height: '65%',
          left: '10%',
          right: '4%',
          // bottom: "10%",
          top: '13%',
          containLabel: true,
        },
        yAxis: {
          type: 'value',
          minInterval: 10,
          axisLabel: {
            textStyle: {
              color: '#fff',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#A8A8A8',
              opacity: 0.4,
            },
          },
        },
        series: [
          {
            data: [],
            type: 'bar',
            showBackground: true,
            color: '#3DFCCD',
            barWidth: 5,
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)',
            },
            itemStyle: {
              //上方显示数值
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    //数值样式
                    color: 'rgba(57, 255, 209, 1)',
                    fontSize: 16,
                  },
                },
              },
            },
          },
        ],
      },
      option2: {
        legend: {
          icon: 'circle',
          bottom: '0',
          left: 'center',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} {d}%'
        },
        series: [
          {
            axisPointer: {
              show: false,
            },

            top: '-23%',
            // with:'85%',
            // height:'85%',
            animation: false,
            type: 'pie',
            radius: [ '30%', '50%' ],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                title: '#fff',
                fontSize: '40',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              // {
              //   value: 24,
              //   name: "叶蝉",
              //   itemStyle: {
              //     color: "rgba(137, 206, 223, 1)",
              //     borderWidth: 0,
              //   },
              // },
              // {
              //   value: 14,
              //   name: "二化螟",
              //   itemStyle: {
              //     color: "rgba(209, 96, 95, 1)",
              //     borderWidth: 0,
              //   },
              // },
              // {
              //   value: 14,
              //   name: "褐飞虱",
              //   itemStyle: {
              //     color: "rgba(60, 136, 196, 1)",
              //     borderWidth: 0,
              //   },
              // },
              // {
              //   value: 14,
              //   name: "灰飞虱",
              //   itemStyle: {
              //     color: "rgba(254, 137, 84, 1)",
              //     borderWidth: 0,
              //   },
              // },
            ],
          },
        ],
      },
    };
  },
  components: {
    Echart,

  },
  mounted() {
    this.getData();

  },
  methods:{
    async getData() {
      await getChxx().then(res => {
        if (res.code == 1) {
          // this.rightData.sqxqData = res.data\
          // this.option.xAxis.data = res.data.map(item=>{
          //   return item.pestLevel
          // })
          this.option.xAxis.data = res.data.pestLevel.split(',');

          if(res.data.pestQuantity.length){
            this.option.series[0].data = res.data.pestQuantity.split(',');
          }



          // this.option2.series.data[0] = res.data.pestQuantity.split(',')
          // this.option.series.data[0]=res.data.map(item=>{
          //   return item.pestQuantity
          // })
          // let list = []
          // if(res.data.disease.length){
          //   list = res.data.disease.split(',').map(item=>{
          //     return {
          //       name:item
          //     }
          //   })
          // }
          // if(res.data.diseaseQuantity.length){
          //   let diseaseQuantity = res.data.diseaseQuantity.split(',')
          //   list = list.map((item,index)=>{
          //     return {
          //       ...item,
          //       value:parseInt(diseaseQuantity[index])
          //     }
          //   })
          // }
          // this.option2.series.data[0]=res.data.map((item,index)=>{
          //   let obj = {
          //       value:item.diseaseQuantity,
          //       name:item.disease,
          //     }
          //   return Object.assign(this.option2.series.data[0][index],obj)
          // })
        }
      });
    }
  },

};
</script>
<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.botton-box {
  overflow: hidden;
  margin-top: companyH(35vh) !important;
  width: companyW(455vw);
  height: companyH(220vh);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  .bar {
    width: companyW(430vw);
    height: companyH(200vh);
  }

  .pie {
    width: companyW(210vw);
    height: companyH(170vh);
  }
}

.title-echarts {
  margin-top: companyH(20vh);
  text-align: center;
  color: #fff;
  font-size: companyH(10vh);
}</style>
