var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title",style:({
    'background-image': `url(${require('@/assets/' +
      _vm.typeOf +
      '/' +
      'snTitle.png')})`,
  })},[_c('div',{staticClass:"title-desc"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"rightBgc anniu1",on:{"click":_vm.jumpshouye}},[_c('img',{staticClass:"imgBox",attrs:{"src":require("../../../assets/njfwimgs/shouye.png")}}),_c('p',[_vm._v("首页")])]),_vm._m(0),_c('div',{staticClass:"rightBgc anniu3",on:{"click":_vm.toback}},[_c('img',{staticClass:"imgBox",attrs:{"src":require("../../../assets/njfwimgs/quanping.png")}}),_c('p',[_vm._v("全屏")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"rightBgc anniu2",attrs:{"href":"http://nsfwgl.ifasun.com","target":"_blank"}},[_c('img',{staticClass:"imgBox",attrs:{"src":require("../../../assets/njfwimgs/houtai.png")}}),_c('p',[_vm._v("后台")])])
}]

export { render, staticRenderFns }