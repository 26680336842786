<template>
  <div class="dry-lastYear">
    <moduleTitle type="dw" title="农业标准地情况"></moduleTitle>
    <div class="dry-lastYear-content">
      <div class="lastYear-content-echart">
        <myCharts   :pdata="listData"></myCharts>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from "./components/moduleTitle.vue";
import myCharts from './components/myCharts.vue';
import Option from "./config/lastyear.js";
import { getStandardPlot } from '../../api/numberApi/index';
export default {
  components: {
    moduleTitle,
    myCharts
  },
  data() {
    return {
      Option,
      listData:[]
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    //调用接口
    async getData() {
      //获取农业主体
      await getStandardPlot().then(res => {
        if (res.code == 1) {
          if (res.code == 1 &&res.data.length) {
            this.listData = res.data.map(item=>{
            return{
              name:item.fieldName,
              value:item.fieldArea,
            }
          })
          this.$forceUpdate()
          }
        }
      })
    },
    
  },
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.dry-lastYear {
  position: absolute;
  bottom: companyH(19vh);
  width: companyW(455vw);
  height: companyH(322vh);
  background: rgba(0, 0, 0, .7);
  // border: companyW(1vw) solid;
  /* opacity: 0.74; */
  .dry-lastYear-content {
    margin: 0 auto;
    margin-top: companyH(50vh);
    width: companyW(400vw);
    height: companyH(260vh);
    .lastYear-content-echart {
      margin: 0 auto;
    margin-top: companyH(40vh);
    width: companyW(400vw);
    height: companyH(260vh);
    }
  }
}
</style>
