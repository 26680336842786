<template>
  <div class="Right">
    <div class="upperright">
      <moduleTitle
        type="xl"
        :downList="downList4"
        v-bind="$attrs"
        v-on="$listeners"
        title="气象信息"
      ></moduleTitle>
      <div class="box">
        <div
          class="box-item"
          @click="openChart('kqwd', rightData.qxData.deviceId, 1)"
        >
          <div class="icons img1"></div>
          <div class="number">
            {{
              rightData.qxData.airTemperature
                ? rightData.qxData.airTemperature.toFixed(2)
                : '0'
            }}℃
          </div>
          <div class="text">空气温度</div>
        </div>
        <div
          class="box-item"
          @click="openChart('kqsd', rightData.qxData.deviceId, 1)"
        >
          <div class="icons img2"></div>
          <div class="number">
            {{
              rightData.qxData.airHumidity ? rightData.qxData.airHumidity : '0'
            }}
          </div>
          <div class="text">空气湿度</div>
        </div>
        <div
          class="box-item"
          @click="openChart('gzqd', rightData.qxData.deviceId, 1)"
        >
          <div class="icons img3"></div>
          <div class="number">
            {{ rightData.qxData.airLight ? rightData.qxData.airLight : '0' }}
          </div>
          <div class="text">光照强度</div>
        </div>
        <div
          class="box-item"
          @click="openChart('jsl', rightData.qxData.deviceId, 1)"
        >
          <div class="icons img4"></div>
          <div class="number">
            {{
              rightData.qxData.airPrecipitation
                ? rightData.qxData.airPrecipitation.toFixed(2)
                : '0'
            }}mm
          </div>
          <div class="text">降水量</div>
        </div>
        <div class="box-item">
          <div class="icons img5"></div>
          <div class="number">
            {{
              rightData.qxData.airSpeed
                ? rightData.qxData.airSpeed.toFixed(2)
                : '0'
            }}m/s
          </div>
          <div class="text">风速</div>
        </div>
        <div class="box-item">
          <div class="icons img6"></div>
          <div class="number">
            {{
              rightData.qxData.airDirection
                ? rightData.qxData.airDirection
                : '\u3000'
            }}
          </div>
          <div class="text">风向</div>
        </div>
        <div class="box-item">
          <div class="icons img7"></div>
          <div class="number">
            {{
              rightData.qxData.airPressure
                ? rightData.qxData.airPressure.toFixed(2)
                : '0'
            }}Pa
          </div>
          <div class="text">大气压</div>
        </div>
        <!-- <div class="box-item">
          <div class="icons img8"></div>
          <div class="number">
            {{ rightData.qxData.airPower ? rightData.qxData.airPower : '0' }}
          </div>
          <div class="text">风力</div>
        </div> -->
      </div>
    </div>
    <div class="lowright">
      <moduleTitle
        v-bind="$attrs"
        :downList="downList1"
        v-on="$listeners"
        type="xl"
        title="墒情信息"
      >
      </moduleTitle>
      <div class="box">
        <div class="gang"></div>
        <div
          class="box-item"
          @click="openChart('trwd', rightData.sqxqData.deviceId, 2)"
        >
          <div class="icons img9"></div>
          <div class="number">
            {{
              rightData.sqxqData.soilTemperature
                ? rightData.sqxqData.soilTemperature.toFixed(2)
                : '0'
            }}℃
          </div>
          <div class="text">土壤温度</div>
        </div>
        <div
          class="box-item"
          @click="openChart('trsd', rightData.sqxqData.deviceId, 2)"
        >
          <div class="icons img10"></div>
          <div class="number">
            {{
              rightData.sqxqData.soilHumidity
                ? rightData.sqxqData.soilHumidity.toFixed(2)
                : '0'
            }}
          </div>
          <div class="text">土壤湿度</div>
        </div>
        <!-- <div
          class="box-item"
          @click="openChart('trph', rightData.sqxqData.deviceId, 2)"
        >
          <div class="icons img11"></div>
          <div class="number">
            {{ rightData.sqxqData.soilPh ? rightData.sqxqData.soilPh : '0' }}
          </div>
          <div class="text">土壤PH</div>
        </div> -->
        <div
          class="box-item"
          @click="openChart('trec', rightData.sqxqData.deviceId, 2)"
        >
          <div class="icons img12"></div>
          <div class="number">
            {{
              rightData.sqxqData.soilEc
                ? rightData.sqxqData.soilEc.toFixed(2)
                : '0'
            }}%
          </div>
          <div class="text">土壤EC</div>
        </div>
        <div class="box-item">
          <div class="icons img13"></div>
          <div class="number">
            {{
              rightData.szData.waterTemperature
                ? rightData.szData.waterTemperature.toFixed(2)
                : '0'
            }}℃
          </div>
          <div class="text">水温</div>
        </div>
        <div class="box-item">
          <div class="icons img14"></div>
          <div class="number">
            {{ rightData.szData.do ? rightData.szData.do : '0' }}
          </div>
          <div class="text">溶解氧</div>
        </div>
        <div class="box-item">
          <div class="icons img15"></div>
          <div class="number">
            {{ rightData.szData.waterPh ? rightData.szData.waterPh : '0' }}
          </div>
          <div class="text">水质PH</div>
        </div>
        <div class="box-item">
          <div class="icons img16"></div>
          <div class="number">
            {{
              rightData.szData.conductivity
                ? rightData.szData.conductivity
                : '0'
            }}
          </div>
          <div class="text">电导率</div>
        </div>
      </div>
    </div>
    <div class="bottomright">
      <moduleTitle v-on="$listeners" type="ck" title="虫情种类"> </moduleTitle>
      <bottomright />
    </div>
  </div>
</template>

<script>
import moduleTitle from '../components/moduleTitle.vue';
import bottomright from './bottomright.vue';

export default {
  components: {
    moduleTitle,
    bottomright
  },
  props: {
    downList1: {
      type: Array,
      default: () => []
    },
    downList4: {
      type: Array,
      default: () => []
    },
    rightData: {
      type: Object,
      default:() => {}
    }
  },
  data () {
    return {
      items: []
    };
  },
  mounted () {},
  methods: {
    openChart (e, id, type) {
      //console.log(type, 'type');
      if (type == 2) {
        this.$listeners.btn(true, {
          title: '墒情信息',
          type: 'chart',
          chartType: e,
          id: id
        });
      } else {
        this.$listeners.btn(true, {
          title: '气象信息',
          type: 'chart',
          chartType: e,
          id: id
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.Right {
  height: 100%;

  .upperright {
    background-color: black;
    position: relative;
    width: companyW(455vw);
    height: companyH(340vh);
    background: rgba(0, 0, 0, 0.7);
    // border: companyW(1vw) solid;

    /* opacity: 0.74; */
    .ceshi {
      width: companyW(472vw);
      width: 33vw;
      height: 4vh;
      background: #e69635;
      position: absolute;
      margin: 10px;
      margin-top: 10px;
    }
  }

  .lowright {
    position: relative;
    top: companyW(25vh);
    width: companyW(455vw);
    height: companyH(350vh);
    background: rgba(0, 0, 0, 0.7);
    // border: companyW(1vw) solid;
  }

  .bottomright {
    /* overflow: hidden; */
    position: absolute;
    bottom: companyH(19vh);
    width: companyW(455vw);
    height: companyH(260vh);
    background: rgba(0, 0, 0, 0.7);
    // border: companyW(1vw) solid;
    /* opacity: 0.74; */
  }
}

.box {
  /* margin: ; */
  position: absolute;
  width: 90%;
  height: 75%;
  top: 20%;
  left: 5%;
  /* margin: companyH(30vh) companyH(30vh) companyH(0vh) companyH(30vh); */
  display: flex;
  flex-wrap: wrap;

  .box-item {
    text-align: center;
    width: companyW(80vw);
    height: companyH(80vh);
    margin: 0 companyW(10vw);

    .img1 {
      background-image: url('../../../assets/number-page/kqsd.png');
    }

    .img2 {
      background-image: url('../../../assets/number-page/kqsdsd.png');
    }

    .img3 {
      background-image: url('../../../assets/number-page/ygqd.png');
    }

    .img4 {
      background-image: url('../../../assets/number-page/jsl.png');
    }

    .img5 {
      background-image: url('../../../assets/number-page/fs.png');
    }

    .img6 {
      background-image: url('../../../assets/number-page/fx.png');
    }

    .img7 {
      background-image: url('../../../assets/number-page/dqy.png');
    }

    .img9 {
      background-image: url('../../../assets/number-page/trsd.png');
    }

    .img10 {
      background-image: url('../../../assets/number-page/trsdsd.png');
    }

    .img11 {
      background-image: url('../../../assets/number-page/trph.png');
    }

    .img12 {
      background-image: url('../../../assets/number-page/trec.png');
    }

    .img13 {
      background-image: url('../../../assets/number-page/trsd.png');
    }

    .img14 {
      background-image: url('../../../assets/number-page/rjy.png');
    }

    .img15 {
      background-image: url('../../../assets/number-page/szph.png');
    }

    .img16 {
      background-image: url('../../../assets/number-page/ddl.png');
    }

    .icons {
      margin: 0 auto;
      width: companyW(40vw);
      height: companyW(40vw);
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .img8 {
      background-image: url('../../../assets/number-page/fl.png');
      margin: companyW(5vw) auto companyW(5vw);
      width: companyW(30vw);
      height: companyW(30vw);
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .number {
      font-weight: bold;
      margin: 0 0 companyW(10vh) 0;
      color: #03c495;
      font-size: companyW(18vw);
    }

    .text {
      font-weight: bold;
      color: #ffffff;
      font-size: companyW(18vw);
    }
  }

  .gang {
    width: 110%;
    height: companyH(5vh);
    position: absolute;
    top: companyH(108vh);
    left: -5%;
    background-image: url('../../../assets/number-page/gang.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>
