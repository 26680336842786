<!--  -->
<template>
  <div class="chartsBox">
    <!-- <div class="itemBox" v-for="(item,index) in pdata" :key="'a'+index">
          
            
        </div> -->
    <div class="itemBox" v-for="(item, index) in pdata" :key="'a' + index">
      <div class="left" :title="item.name">
        {{ item.name }}
      </div>
      <div class="right">{{ item.value }}亩</div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  props: ["pdata"],
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    pdata: {
      handler(a, b) {
        //console.log(a,'aaaaa');
      },
      deep: true,
    },
  },
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.chartsBox {
  width: 100%;
  height: calc(100% - companyH(35vh));
  padding-top: companyH(20vh);
  padding: companyH(20vh) companyW(0vw);
  overflow-y: auto;

  .itemBox {
    width: 90%;
    height: companyH(50vh);
    line-height: companyH(50vh);
    margin-bottom: companyH(10vh);
    background-image: linear-gradient(to right, #062622, #090b0f);
    border-radius: companyW(5vw);
    padding: 0 companyH(15vh);
    display: flex;
    justify-content: space-between;
    .left {
      width: companyW(230vw);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #fff;
    }
    .right {
      color: #00e7ef;
    }
  }

  .qs {
    border: 1px solid #33c7a3 !important;
    background-color: #195a4a !important;
  }
  .qsvalue {
    color: #c3d7f8 !important;
  }
}
::v-deep .type1 {
  .el-progress-bar__inner {
    background-color: #38d2ad;
  }
}
::v-deep .type2 {
  .el-progress-bar__inner {
    background-color: #2d8678;
  }
}
/*css主要部分的样式*/
/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 6px; /*对垂直流动条有效*/
  height: 6px; /*对水平流动条有效*/
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track {
  border-radius: 4px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  /* background-color: rosybrown; */
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #07b185;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
}

/*定义滑块悬停变化颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb:hover {
  background-color: #07b185;
}

/*定义两端按钮的样式*/
::-webkit-scrollbar-button {
  /* background-color: cyan; */
}

/*定义右下角汇合处的样式*/
::-webkit-scrollbar-corner {
  /* background: khaki; */
}

/* 隐藏滚动条 */
/* ::-webkit-scrollbar {
    display: none;
} */
</style>
