<template>
  <div class="middle-box">
    <keep-alive>
      <Map
      ref="map"
      :key="isShow"
      :isShow="isShow"
      v-on="$listeners"
      v-bind="$attrs"
    />
    </keep-alive>
   
    <!-- <div class="redBtn"></div> -->
    <!-- <div class="greenBtn"></div> -->
    <div class="btn">
      <div @click="clickShow(1)" :class="isShow==1?'green':'grey'">永久基本农田</div>
      <br />
      <div @click="clickShow(2)" :class="isShow==2?'yellow':'grey'">粮食功能区</div>
    </div>
    <div class="dw" @click="backDw"></div>
    <div class="middle">
      <div class="middle-nav">
        <navList v-on="$listeners" v-bind="$attrs" />
      </div>
      <div class="tz">
        <div class="tznr">
          <div class="tznr1" v-if="this.tzList.length">
            <div class="tzicon"></div>
            <div class="tztitle">
              {{ this.tzList.length ? this.tzList[index].title : "" }}
            </div>
          </div>
          <div class="tznr1" v-else>
            <div class="tzicon"></div>
            <div class="tztitle">暂无预警</div>
          </div>
          <div class="tzsj">
            <div>{{ this.tzList.length ? this.tzList[index].time : "" }}</div>
            <div class="upordown">
              <div class="up" @click="goTo('up')"></div>
              <div class="down" @click="goTo('down')"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from "../components/map.vue";
import navList from "../components/navList.vue";
import storageAndDry from "./storageAndDry.vue";

export default {
  components: {
    navList,
    storageAndDry,
    Map,
  },
  props: {
    tzList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    tzList: {
      handler(newData, old) {
        let time;
        if (newData) {
          clearInterval(time);
          time = window.setInterval(() => {
            //console.log(this.index,'this.index');
            if (this.index >= newData.length - 1) {
              this.index = 0;
              return;
            }
            this.index = this.index + 1;
          }, 2000);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
     
      current: {
        title: "",
        time: "",
      },
    
      isShow: 1,
      index: 0,
      navList: [
        {
          name: "农田总面积",
          number: "5622亩",
          bgcTitle: require("../../../assets/number-page/topbg.png"),
        },
        {
          name: "粮食功能区",
          number: "15家",
          bgcTitle: require("../../../assets/number-page/topbg.png"),
        },
        {
          name: "农业主题",
          number: "6个",
          bgcTitle: require("../../../assets/number-page/topbg.png"),
        },
        {
          name: "种植补贴",
          number: "5,421,584元",
          bgcTitle: require("../../../assets/number-page/topbg.png"),
        },
      ],
    };
  },
  methods: {
    clickShow(e) {
      this.isShow = e;
    },
    backDw() {
      this.$refs.map.dw();
    },
    goTo(type) {
      if (type == "down") {
        if (this.index + 1 < this.tzList.length) {
          this.current = this.tzList[this.index + 1];
          this.index = this.index + 1;
        }
      } else if (this.index - 1 >= 0) {
        this.current = this.tzList[this.index - 1];
        this.index = this.index - 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.middle-box {
  width: companyW(924vw);
  top: companyH(100vh);
  height: companyH(680vh);
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  overflow: hidden;
  background-image: url("../../../assets/number-page/jx.png");
  background-size: 100% 30%;
  background-repeat: no-repeat;
}
.middle {
  top: 0;
  height: companyH(200vh);
  left: 50%;
  width: 100%;
  transform: translate(-50%);
  position: absolute;
  overflow: hidden;
  background-image: url("../../../assets/number-page/jx.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .middle-nav {
    width: companyW(850vw);
    height: companyH(65vh);
    margin: 0 auto;
    margin-top: companyH(44vh);
  }
  .middle-tray {
    position: absolute;
    margin-left: companyW(-70vw);
    width: companyW(1092vw);
    height: companyH(230vh);
    z-index: 99999;
  }
}
.tz {
  width: 100%;
  height: 100px;
  align-items: center;
  .tznr {
    position: absolute;
    justify-content: space-between;
    top: companyH(120vh);
    left: companyW(60vw);
    display: flex;
    padding: companyH(15vh) companyW(20vw) 0 companyW(20vw);
    vertical-align: middle;
    width: companyW(760vw);
    height: companyH(30vh);
    background: url("../../../assets/number-page/tzbjs.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .tznr1 {
      display: flex;
      .tzicon {
        width: companyH(20vh);
        height: companyH(20vh);
        margin: 0 companyH(10vh) 0 0;
        background-image: url(../../../assets/number-page/tzicon.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .tztitle {
        color: #fff;
        font-size: companyH(17vh);
      }
    }
    .tzsj {
      color: #fff;
      font-size: companyH(17vh);
      display: flex;
      .upordown {
        margin-left: companyW(10vw);
        width: companyW(10vw);
        height: companyH(20vh);
        .up {
          width: companyW(10vw);
          height: companyH(8vh);
          background-image: url("../../../assets/number-page/tzup.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-bottom: companyH(2vh);
        }
        .down {
          width: companyW(10vw);
          height: companyH(8vh);
          background-image: url("../../../assets/number-page/tzdown.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
.redBtn {
  width: companyW(140vw);
  height: companyH(40vh);
  position: absolute;
  background: url("../../../assets/number-page/zynt.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: companyW(920vh);
  left: companyH(18vw);
}
.greenBtn {
  width: companyW(140vw);
  height: companyH(40vh);
  position: absolute;
  background: url("../../../assets/number-page/fwnt.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: companyW(1020vh);
  left: companyH(18vw);
}
.dw {
  width: companyW(60vw);
  height: companyW(60vw);
  position: absolute;
  background: url("../../../assets/number-page/dw.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: companyW(1020vh);
  right: companyH(18vw);
}
.btn {
  position: absolute;
  top: companyW(920vh);
  left: companyH(18vw);
  .yellow{
    background-image: url('../../../assets/number-page/yellow.png');
  }
  .green{
    background-image: url('../../../assets/number-page/green.png');
  }
  .grey{
    background-image: url('../../../assets/number-page/grey.png');
  }
  div {
    width: companyW(115vw);
    height: companyH(50vh);
    color:#fff;
    line-height: companyH(50vh);
    padding-left: companyW(50vw);
    margin-bottom: companyH(1vh);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

}
</style>
