import * as echarts from 'echarts';
export default{
  // title: {
  //   text: 'World Population'
  // },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {},
  grid: {
    height:'90%',
    left: '0%',
    right: '4%',
    top:'5%',
    containLabel: true,
  },
  xAxis: {
    type: 'value',
    boundaryGap: [0, 0.01],

    axisLabel:{
      textStyle:{
        color:'#fff',
        opacity:0,
        fontSize:14
      },
    },
    
    splitLine:{
      show:true,
      lineStyle:{
        color:'#fff',
        opacity:0.4
      }
      
    }
  },
  yAxis: {
    show:true,
    axisLabel:{
      textStyle:{
        color:'#fff',
        fontSize:16
      },
    },
    type: 'category',
    data: []
  },
  series: [
    {
      type: 'bar',
      data: [],
      color: '#3DFCCD',
      barWidth:5,
      showBackground:false,
    }
  ]
};

