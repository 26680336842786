<template>
  <div id="yzMap"></div>
</template>
<script>
import MapInit from '@/components/tianMap/MapInit.js';

export default {
  data () {
    return {
      map,
      zoom: 12,
      countries: [],
      T:null,
    };
  },
  mounted () {
    if(this.divList.length){
      this.roadMap();
    }else{
      setTimeout(()=>{
      this.roadMap();
    },500)
    }
  },
  props: {
    divList: {
      type: Array
    },
    isShow:1,
  },
  watch: {
    // divList: {
    //   handler (newDate, old) {
    //     if (newDate.length) {
    //       this.roadMap();
    //       // this.map.checkResize()
    //     } else {
    //       this.roadMap();
    //     }
    //   },
    //   deep: true
    // },
  },
  methods: {
    addClickHandler (content, marker) {
      let _this = this;
      marker.addEventListener('click', function (e) {
        _this.openInfo(content, e);
      });
    },
    openInfo (content, e) {
      var point = e.lnglat;
      var markerInfoWin = new T.InfoWindow(content, {
        offset: new T.Point(0, -30)
      }); // 创建信息窗口对象
      this.map.openInfoWindow(markerInfoWin, point); //开启信息窗口
    },
    init(sel, transform) {
      var upd = sel.selectAll('path.geojson1').data(this.countries);
      console.log(upd,'upd');
      upd
        .enter()
        .append('path')
        .attr('class', 'geojson1')
        .attr('stroke', 'black')
        .attr('fill', function (d, i) {
          return d3.hsl(Math.random() * 360, 0.9, 0.5);
        })
        .attr('fill-opacity', '0.5');
    },
    init1(sel, transform) {
      let color = this.isShow==1?120:60
      var upd = sel.selectAll('path.geojson1').data(this.countries);
      console.log(upd[0].length,'upd');
      upd
        .enter()
        .append('path')
        .attr('class', 'geojson1')
        .attr('stroke', 'black')
        .attr('fill', function (d, i) {
          // console.log(d,'d');
       
       
            return d3.hsl(color, 0.9, 0.5);
         
          
          // if(i>(upd[0].length/2)){
          //   return d3.hsl(120, 0.9, 0.5);
          // }else{
          //   return d3.hsl(240, 0.9, 0.5);
          // }
        })
        .attr('fill-opacity', '0.5');
    },
    redraw(sel, transform) {
      sel.selectAll('path.geojson').each(function (d, i) {
        d3.select(this).attr('d', transform.pathFromGeojson(d)); // 使用正确的属性名
      });
    },
    redraw1(sel, transform) {
      sel.selectAll('path.geojson1').each(function (d, i) {
        d3.select(this).attr('d', transform.pathFromGeojson(d)); // 使用正确的属性名
      });
    },
    roadMap() {
      MapInit.init()
        .then(T => {
          this.T = T;
          const imageURL =
            'https://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=c9cd7951db1ea5b311aae41f5c0d60e4';
          const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
          var countriesOverlay =new T.D3Overlay(this.init, this.redraw);
          var countriesOverlay1 =new T.D3Overlay(this.init1, this.redraw1);
          // const lay2 = new T.TileLayer(imageURLT, { minZoom: 1, maxZoom: 18 });
          const config = { layers: [ lay ] };
          this.map = new T.Map('yzMap', config);
          this.map.addControl(window.TMAP_SATELLITE_MAP);
          let lng = JSON.parse(localStorage.getItem('key')).latitude;
          let lon = JSON.parse(localStorage.getItem('key')).longitude;
          this.map.centerAndZoom(new T.LngLat(lon, lng), 14);

          //创建图片对象
          var icon;
          for (var i = 0; i < this.divList.length; i++) {
            if (this.divList[i].category === 1 && this.divList[i].status == 0) {
              //气象2
              icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312227846气象2.png',
                iconSize: new T.Point(30, 30),
                iconAnchor: new T.Point(30, 30)
              });
            } else if (
              this.divList[i].category === 2 &&
              this.divList[i].status == 0
            ) {
              icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312228386土壤2.png',
                iconSize: new T.Point(30, 30),
                iconAnchor: new T.Point(30, 30)
              });
            } else if (
              this.divList[i].category === 3 &&
              this.divList[i].status == 0
            ) {
              icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312224851虫2.png',
                iconSize: new T.Point(30, 30),
                iconAnchor: new T.Point(30, 30)
              });
            } else if (
              this.divList[i].category === 4 &&
              this.divList[i].status == 0
            ) {
              icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312222897长势2.png',
                iconSize: new T.Point(30, 30),
                iconAnchor: new T.Point(30, 30)
              });
            } else if (
              this.divList[i].category === 5 &&
              this.divList[i].status == 0
            ) {
              icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312225604水质2.png',
                iconSize: new T.Point(30, 30),
                iconAnchor: new T.Point(30, 30)
              });
            } else if (
              this.divList[i].category === 1 &&
              this.divList[i].status == 1
            ) {
              icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312224753气象1.png',
                iconSize: new T.Point(30, 30),
                iconAnchor: new T.Point(30, 30)
              });
            } else if (
              this.divList[i].category === 2 &&
              this.divList[i].status == 1
            ) {
              icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312229975土壤1.png',
                iconSize: new T.Point(30, 30),
                iconAnchor: new T.Point(30, 30)
              });
            } else if (
              this.divList[i].category === 3 &&
              this.divList[i].status == 1
            ) {
              icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312227132虫1.png',
                iconSize: new T.Point(30, 30),
                iconAnchor: new T.Point(30, 30)
              });
            } else if (
              this.divList[i].category === 4 &&
              this.divList[i].status == 1
            ) {
              icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312229936长势1.png',
                iconSize: new T.Point(30, 30),
                iconAnchor: new T.Point(30, 30)
              });
            } else if (
              this.divList[i].category === 5 &&
              this.divList[i].status == 1
            ) {
              icon = new T.Icon({
                iconUrl:
                  'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202312225203水质1.png',
                iconSize: new T.Point(30, 30),
                iconAnchor: new T.Point(30, 30)
              });
            }
            var marker = new T.Marker(
              new T.LngLat(this.divList[i].longitude, this.divList[i].latitude),
              { icon: icon }
            ); // 创建标注
            var content = this.divList[i].deviceName;
            this.map.addOverLay(marker); // 将标注添加到地图中
            this.addClickHandler(content, marker);
          }
          let marker1 = new T.Marker(new T.LngLat(lon, lng)); // 创建标注
          let content1 = JSON.parse(localStorage.getItem('key')).name;
          this.map.addOverLay(marker1);
          this.addClickHandler(content1, marker1);
          d3.json(this.isShow===1?
            'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/2024061415673.geojson'
            :'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/2024061494274.geojson',
            function (data) {
              this.countries = data.features;
              this.map.addOverLay(countriesOverlay);
              countriesOverlay.bringToBack();
              this.map.addOverLay(countriesOverlay1);
              countriesOverlay1.bringToBack();
            }.bind(this) // 绑定this到回调函数中
          );
        })
        .catch();
    },
    MapClick (e) {
      // ////console.log(e.lnglat.getLng()+","+e.lnglat.getLat());
      // this.$listeners.btn(true, 'map')
    },
    dw () {
      let lng = JSON.parse(localStorage.getItem('key')).latitude;
      let lon = JSON.parse(localStorage.getItem('key')).longitude;

      this.map.centerAndZoom(new T.LngLat(lon, lng), 14);
    }
  }
  // init() {
  //   return new Promise((resolve, reject) => {
  //     // 如果已加载直接返回
  //     if (window.T) {
  //       //console.log('地图脚本初始化成功...')
  //       resolve(window.T)
  //       reject('error')
  //     }
  //   })
  // }
};
</script>
<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

#yzMap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}

::v-deep {
  .tdt-control {
    display: none;
  }
}
</style>
