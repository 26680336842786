<template>
  <div>
    <el-dialog :visible.sync="chaMask" :show-close="false" @close="closeMask">

      <dv-border-box-1 v-if="chaMask">
        <div class="close"  @click="closeMask"></div>
        <div class="ToolBox" v-if="modelType==='table'">
          <slot></slot>
          <div class="NavList" v-if="NavList.length != 0">
            <div
              :class="item.id == currentInd ? 'NavOne currentNav' : 'NavOne'"
              v-for="item in NavList"
              :key="item.id"
              @click="changeNav(item.id)"
            >
              <div class="NavTitle">{{ item.label }}</div>
              <div class="NavTitle">
                <span>{{ item.value }}</span>
                <span class="NavSpan">{{ item.unit }}</span>
              </div>
            </div>
          </div>
          <div>
            <el-table
            stripe
            v-loading="tabLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="TanleContent"
            style="width: 45vw"
            height="50vh"
          >
            <template v-for="v in mainTable">
              <el-table-column
                v-if="v.png"
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
                @cell-mouse-enter="ent"
              >
                <template slot-scope="scope">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="scope.row[v.prop]"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column
                v-else-if="v.btn"
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
                @cell-mouse-enter="ent"
              >
                <template slot-scope="scope">
                  <div class="toolStyle">{{ scope.row[v.prop] }}</div>
                </template>
              </el-table-column>
              <el-table-column
                v-else-if="v.pj"
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
                @cell-mouse-enter="ent"
              >
                <template slot-scope="scope">
                  <el-rate
                    v-model="scope.row[v.prop]"
                    disabled="true"
                    :colors="['#009ad6', '#009ad6', '#009ad6']"
                    disabled-void-color="#77787b"
                  ></el-rate>
                </template>
              </el-table-column>
              <el-table-column
                v-else
                :key="v.id"
                align="center"
                :prop="v.prop"
                :label="v.label"
                :sortable="v.isSort"
                :min-width="v.width"
                @cell-mouse-enter="ent"
              ></el-table-column>
            </template>
          </el-table>
          <div class="sz-pagination">
            <el-pagination
            :current-page.sync="currentPage"
              @current-change="currentChange"
              layout=" slot,prev, pager, next"
              :total="tableTotal">
              <span class="fy-slot">共{{tableTotal}}条记录</span>
            </el-pagination>
          </div>
        </div>
        </div>
        <!-- 点击查看按钮 -->
        <ckModel v-else-if="modelType==='chart'" v-bind="$attrs"/>
        <mapModel v-else-if="modelType==='map'"></mapModel>
      </dv-border-box-1>
    </el-dialog>
  </div>
</template>
<script>
// import MidChart from "@/views/njall/components/njzl/ToolChart.vue";
// import * as options from "@/views/njall/mock/njToolMock.js";
import ckModel from './components/ckModel.vue';
import mapModel from './components/mapModel.vue';
export default {
  components: {
    ckModel,
    mapModel
  },
  props: {
    tableTotal: {
      type: Number,
      default: 0,
    },
    changeMask: {
      type: Boolean,
      default: false,
    },
    maskBoxData:{
      type: Boolean,
      default: {

      },
    },
    mainTable: {
      type: Array,
      default: () => [],
    },
    NavList: {
      type: Array,
      default: () => [],
    },
    TanleContent: {
      type: Array,
      default: () => [],
    },
    chaMask: {
      type: Boolean,
      default: false,
    },
    modelType:{
      type: String,
      default: '',
    },
    stateParams: {
      type: Object,
      default: {

      },
    },

  },
  data() {
    return {
      currentPage:1,
      tabLoading: true,
      currentInd: 1,
      // ...options,

      rateColor: [ '#009ad6' ],
    };
  },
  watch: {
    changeMask: {
      handler(newv, oldv) {
        this.chaMask = newv;
      },
    },
  },
  methods: {
    //页面改变
    currentChange(e){
      this.tabLoading = true;
      this.$listeners.getTabal(e,this.stateParams);
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },
    closeMask() {
      this.currentPage =1;
      this.chaMask = false;
      this.$listeners.btn(false);
    },
    changeNav(id) {
      this.tabLoading = true;
      this.currentInd = id;
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },
    ent() {},
  },
  mounted() {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
    //console.log(this.chaMask);
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
.close{
  width: companyW(37vw);
  height: companyH(37vh);
  background: url('../../assets/close.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: companyH(25vh);
  right: companyW(35vw);

}
::v-deep .el-dialog {
  width: companyW(1499vw);
  background: #000000;
  opacity: 0.9;
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
::v-deep .el-dialog__body {
  padding: 0;
}
/* 分页器样式 */
.sz-pagination{
  margin-top: companyH(10vh);
  text-align: center;
  width: 100%;
  .fy-slot{
    margin-right: companyW(10vw);
  color: rgb(2, 167, 125);
}
::v-deep{
  .btn-prev{
    background: #000;
  }
  .el-pager{
    background: #000;
    color: rgb(1, 193, 144);

    .number{
      background: #000;
      border: 1px solid rgb(1, 193, 144);
    }
    .number:hover{
      color:#fff
    }
  }
  .btn-next{
    background-color: black;

  }
  .el-icon-arrow-right{
    background-color: black;
  }
  .el-icon-arrow-left:before{
    color: rgb(2, 167, 125);
    border: 1px solid rgb(1, 193, 144);
  }
  .el-icon-arrow-right:before{
    color: rgb(2, 167, 125);
    border: 1px solid rgb(1, 193, 144);
  }
  .active{
    color: #fff;
    font-weight: 400;
  }
  .el-pager li.btn-quicknext, .el-pager li.btn-quickprev{
    background-color: #000;
    color:rgb(2, 167, 125);
    border:1px solid rgb(1, 193, 144)
  }
}
}

.ToolBox {
  width: 95%;
  // height: companyH(605vh);
  margin: 0 auto;
  padding: companyH(88vh) 0;
  display: flex;
  .NavList {
    display: flex;
    flex-direction: column;
    margin-right: companyW(10vw);
    .NavOne {
      width: companyW(165vw);
      height: companyH(57vh);
      /* background-image: url(../../../assets/njImgs/toolNav.png); */
      background-size: cover;
      margin-bottom: companyH(10vh);
      opacity: 0.3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #33f8f8;
      cursor: pointer;
      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(15.2vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #33f8f8;
        .NavSpan {
          font-size: companyH(12vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
    }
  }
}
.NavOne:hover {
  opacity: 0.7 !important;
  transition: all 0.5s;
}
.currentNav {
  opacity: 1 !important;
}
::v-deep .el-table th {
  background: rgb(1, 62, 46);
  border-top: companyW(1vw) solid rgb(1, 105, 78);
  border-bottom: companyW(1vw) solid rgb(1, 105, 78);
  height: companyH(35vh);
  padding: 0;
  text-align: center;
}
::v-deep .el-table tr{
  border:companyW(1vw) solid rgb(1, 105, 78);
}
::v-deep .el-table::before {
  z-index: inherit;
}
::v-deep .cell {
  font-size: companyH(14vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}
::v-deep .el-table,
.el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0);
}
::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  border-bottom: 1px solid rgba(238, 238, 238, 0);
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
  // opacity: 0.1;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-table tr,
::v-deep .el-table::before,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #000000;
}
.toolStyle {
  color: "#33f8f8" !important;
  font-size: companyH(14vh);
  text-decoration: underline;
  cursor: pointer;
}
</style>
