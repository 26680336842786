<template>
  <div class="leftImg">
    <!-- <div class="tcTitle">仓前街道</div> -->
    <img src="@/assets/njImgs/maskCount.png" alt="" />
    <div class="tiBox">
    <div>{{ stateParams.title }}</div>
    <div>{{ stateParams.count }}</div>
    </div>
</div>
 </div>
</template>

<script>
export default {
  props: {
    stateParams: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.leftImg {
  width: companyW(540vw);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: companyW(578vw);
    height: companyH(590vh);
  }

  .tiBox {
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    div:nth-child(1) {
      font-size: companyH(22vh);
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: companyH(47vh);
      margin-top: companyH(106vh);
    }
    div:nth-child(2) {
      font-size: companyH(70vh);
      font-family: DIN;
      font-weight: 400;
      color: #ffffff;
      text-shadow: 1px 1px 1px #33f8f8;
      -webkit-text-stroke: 1px #fff;
      -webkit-filter: drop-shadow(1px 1px 0px #33f8f8);
    }
  }
  .tcTitle{
    padding: companyH(3vh) companyW(10vw);
    position: absolute;
    color: #fff;
    font-weight: bold;
    background-color: rgba(0, 67, 49, 1);
    left: companyW(50vw);
    top: companyH(120vh);
  }
}
</style>